body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121a61;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

text-success {
  color:#FFC857 !important;
  font-style: italic;
}

.fade-in-card { 
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  background: #f5f5f5;
  cursor:default; 

}

@media (hover: hover) {
  .fade-in-card:hover { 
    background: #b6ccfe;
    transform: translate(5px, 0px);
    cursor: pointer; 
  }
}
.selected-card { 
  background: #b6ccfe !important;
}
 
.aboutSection:focus-within{
  background: #b6ccfe;
  transform: translate(5px, 0px);
}

@media screen and (max-width: 600px)  {
  .mobile-break { display: none; }
}

@media screen and (max-width: 600px)  {
  .mobile-font-size { font-size: 0.7em; }
}
